import React, { useEffect, useState } from 'react';
import Header from '../components/Header';
import Footer2Mobile from '../components/Footer2_Mobile';
import Footer2 from '../components/Footer2';
import useWindowSize from '../hooks/useWindowSize';
import SecondHeader from '../components/SecondHeader';
import TextComponent from '../components/Text';

const TipsAndTricks: React.FC = () => {
  const { width } = useWindowSize();
  const isMobile = width <= 768;

  const [content, setContent] = useState<string>(`# Der Unterschied zwischen klassischer Suche und Prompting

Heutzutage gibt es verschiedene Methoden, um Informationen zu finden. Zwei häufig verwendete Ansätze sind die klassische Suche und das Prompting. Obwohl beide dazu dienen, Antworten auf Fragen zu finden, unterscheiden sie sich in ihrem Ansatz und ihrer Funktionsweise erheblich.

## Klassische Suche

Die klassische Suche, wie sie von Suchmaschinen wie Google angeboten wird, basiert auf der Eingabe von Schlüsselwörtern oder Phrasen in ein Suchfeld. Die Suchmaschine durchsucht dann Internetseiten oder Datenbanken, die diese Schlüsselwörter enthalten, und präsentiert dem Benutzer eine Liste von Ergebnissen, die den eingegebenen Kriterien entsprechen. Der Benutzer muss dann die Ergebnisse durchgehen und die relevantesten auswählen.

## Prompting

Im Gegensatz dazu verwendet das Prompting einen interaktiveren Ansatz, wie es bei WHATSLEGAL verwendet wird. Anstatt nach spezifischen Schlüsselwörtern zu suchen, fordert das Prompting den Benutzer auf, eine Frage, ein Problem oder einen Sachverhalt in natürlicher Sprache zu formulieren. Basierend auf dieser Eingabe generiert WHATSLEGAL eine Antwort oder eine Lösung, indem es auf vorheriges Wissen oder Datenbanken zurückgreift. Das Ziel des Promptings ist es, dem Benutzer auf direktem Weg relevante Informationen bereitzustellen, ohne dass er sich durch eine Liste von Suchergebnissen arbeiten muss.

Ein einfacher Vergleich wäre die klassische Suche mit einem Bibliothekskatalog, in dem man nach Büchern anhand von Stichwörtern sucht, während das Prompting eher einem Bibliothekar ähnelt, der sofort eine Empfehlung oder Antwort auf eine gestellte Frage liefert.
`);
  // useEffect(() => {
  //   // Fetch the markdown file
  //   fetch('/termsAndTricks.md') // Adjust the path if necessary
  //     .then(response => response.text())
  //     .then(data => setContent(data))
  //     .catch(error => console.error('Error fetching markdown:', error));
  // }, []);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  
  const containerStyle: React.CSSProperties = {
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100vh',
  };

  const mainContentStyle: React.CSSProperties = {
    flex: '1',
  };

  return (
    <div style={containerStyle}>
      <Header />
      <TextComponent content={content} />
      {isMobile ? <Footer2Mobile /> : <Footer2 />}
    </div>
  );
};

export default TipsAndTricks;
