import React, { useState } from 'react';
import { Link, useLocation } from 'react-router-dom'; // Import useLocation
import whatsLegalHeaderLogo from "../assets/WhatsLegal_logo_neu_2.png";
import { FaBars, FaTimes } from 'react-icons/fa';
import './css/Header.css'; 

const Header: React.FC = () => {
  const [isNavOpen, setIsNavOpen] = useState(false);
  const location = useLocation(); // Hook to get the current path

  const toggleNav = () => {
    setIsNavOpen(!isNavOpen);
  };

  const isActive = (path: string) => location.pathname === path;

  return (
    <div className="mainHeader">
      <div className="logoContainer">
        <Link to="/" onClick={() => setIsNavOpen(false)}>
          <img src={whatsLegalHeaderLogo} className="websiteLogo" alt="WhatsLegal.ai Logo" />
        </Link>
        {/* <span className="tagline">KI ASSISTENT FÜR RECHT<br/>KOSTENLOS - FÜR ALLE</span> */}
      </div>
      <nav className={`nav ${isNavOpen ? 'nav-open' : 'nav-close'}`}>
        <Link 
          to="/whatslegalchat" 
          className={`nav-item ${isActive('/whatslegalchat') ? 'active' : ''}`}
          onClick={() => setIsNavOpen(false)}
        >
          Freie Version
        </Link>
        <Link 
          to="/whitelabel" 
          className={`nav-item ${isActive('/whitelabel') ? 'active' : ''}`}
          onClick={() => setIsNavOpen(false)}
        >
          Kunden Version
        </Link>
      </nav>
      <button className="nav-toggle" onClick={toggleNav}>
        {isNavOpen ? <FaTimes /> : <FaBars />}
      </button>
    </div>
  );
}

export default Header;
