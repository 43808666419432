import React, { useEffect, useState } from 'react';
import Header from '../components/Header';
import Footer2Mobile from '../components/Footer2_Mobile';
import Footer2 from '../components/Footer2';
import useWindowSize from '../hooks/useWindowSize';
import SecondHeader from '../components/SecondHeader';
import TextComponent from '../components/Text';

const PolicyTerms: React.FC = () => {
  const { width } = useWindowSize();
  const isMobile = width <= 768;
  const [content, setContent] = useState<string>(`## 1. Registrierung

Beim Registrieren erstellen Sie Ihren Login-Namen und Ihr Login-Passwort. Bitte vergewissern Sie sich, dass Sie diese Daten an einem sicheren Ort aufbewahren und nicht an andere Personen weitergeben. Sie sind für alle Daten, Texte und Kommentare unter Ihrem Benutzernamen und Passwort voll verantwortlich. Sollte jemand Ihr Passwort und Ihren Login-Namen missbräuchlich verwenden oder einen sonstigen Rechtsverstoß begehen, ist es Ihre Verantwortung, uns dies unverzüglich mitzuteilen, nachdem Sie davon Kenntnis erhalten haben. Wir behalten uns das Recht vor, Benutzer um eine Änderung des Login-Namens oder Login-Passworts zu ersuchen, falls dies aus Sicherheitsgründen notwendig ist.

## 2. Information

Ihre Registrierung erfordert eine gültige persönliche E-Mail-Adresse, von der Sie regelmäßig E-Mails abrufen, damit wir Ihnen Änderungs-E-Mails senden können. Sollten Sie eine E-Mail-Adresse einer anderen Person oder eine vorübergehende E-Mail-Adresse verwenden, kann Ihre Registrierung ohne vorausgehende Warnung gelöscht werden. Falls wir annehmen müssen, dass eine ungültige E-Mail-Adresse verwendet wurde, werden Sie aufgefordert, Ihre Registrierung erneut zu bestätigen. Wir behalten uns das Recht vor, Registrierungen nach Ankündigung zu löschen.

## 3. Gewährleistungsausschluss

Die Nutzung dieser Website erfolgt auf eigene Gefahr. Weder WhatsLegal noch die Weisscam GmbH noch eine andere an der Programmierung oder Fertigstellung der Website beteiligte Partei haftet für direkte, beiläufige, indirekte oder Strafe einschließende Schäden oder Folgeschäden, die aus Ihrem Zugriff auf diese Website oder deren Nutzung entstanden sind. Dies schließt Schäden an Ihrer Computerausrüstung sowie Viren, die diese infizieren können, ein. 

Ohne das Voranstehende einzuschränken, wird Ihnen der gesamte Inhalt dieser Website "wie besichtigt" ohne Gewährleistungen einer allgemeinen Gebrauchstauglichkeit, einer Eignung für einen bestimmten Zweck oder eines Nicht-Verstoßes gegen ein Recht zur Verfügung gestellt. Beachten Sie bitte, dass einige Länder den Ausschluss von stillschweigenden Zusicherungen nicht zulassen. Überprüfen Sie daher Ihre vor Ort geltenden Gesetze.

Wir weisen ausdrücklich darauf hin, dass WhatsLegal bzw. Weisscam GmbH keine Rechtsberatung anstrebt, anbietet oder betreibt. Für die Ergebnisse, die WhatsLegal als Antwort liefert, übernimmt weder WhatsLegal noch die Weisscam GmbH Verantwortung für die Richtigkeit. Die Ergebnisse, die WhatsLegal liefert, stellen in keiner Weise eine Rechtsberatung dar. Wir möchten ausdrücklich darauf hinweisen, dass die Antworten schlimmstenfalls frei erfunden (Fachausdruck: halluzinieren) sein können. Als Deeptech-StartUp unternehmen wir große Anstrengungen und treffen Vorkehrungen, um bestmöglich Halluzinationen von LLMs (Large Language Models) zu unterbinden und auf sichere Quellen zu verweisen. Eine 100%ige Garantie gibt es dafür nicht. Wir weisen jegliche Ansprüche zurück, die sich auf die Richtigkeit der Ergebnisse beziehen, die WhatsLegal liefert.

Es liegt allein in Ihrer eigenen Verantwortung, ob und wie Sie WhatsLegal nutzen und wie Sie mit den Ergebnissen umgehen, die WhatsLegal liefert. Wir empfehlen, fachliche Rechtsanwälte oder Rechtsanwältinnen zu Rate zu ziehen, um eine rechtliche Einschätzung vorzunehmen.
`);
  
  
  

  const containerStyle: React.CSSProperties = {
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100vh',
  };

  const mainContentStyle: React.CSSProperties = {
    flex: '1',
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  
  return (
      <div style={containerStyle}>
      <Header />
      <TextComponent content={content} />
      {isMobile ? <Footer2Mobile /> : <Footer2 />}
    </div>
  );
};

export default PolicyTerms;
