import React from 'react';
import './css/VersionDisplay.css';

interface VersionDisplayProps {
  embeddedImage: string;
  overlayImage: string;
}

const VersionDisplay: React.FC<VersionDisplayProps> = ({ embeddedImage, overlayImage }) => {
  return (
    <div className="version-container">
    <h2 className="main-title">Wir bieten White Label in zwei Versionen an</h2>
    <div className="version-display">
      <div className="version-column">
        <h2>Embedded Version</h2>
        <img src={embeddedImage} alt="Embedded Version" className="version-image" />
      </div>
      <div className="version-column">
        <h2>Overlay Version</h2>
        <img src={overlayImage} alt="Overlay Version" className="version-image" />
      </div>
    </div>
    </div>
  );
};

export default VersionDisplay;
