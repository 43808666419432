// FloatingButton.tsx
import React from 'react';
import './css/FloatingButton.css';

interface FloatingButtonProps {
  onClick: () => void;
  icon?: React.ReactNode;
}

const FloatingButton: React.FC<FloatingButtonProps> = ({ onClick, icon = '💬' }) => {
  return (
    <button className="floating-button pulse" onClick={onClick}>
      {icon}
    </button>
  );
};

export default FloatingButton;
