import React, { useEffect, useState } from 'react';
import Header from '../components/Header';
import Footer2Mobile from '../components/Footer2_Mobile';
import Footer2 from '../components/Footer2';
import useWindowSize from '../hooks/useWindowSize';
import SecondHeader from '../components/SecondHeader';
import TextComponent from '../components/Text';

const Imprint: React.FC = () => {
  const { width } = useWindowSize();
  const isMobile = width <= 768;

  const [content] = useState<string>(`
# Angaben gemäß § 5 TMG

**WhatsLegal GmbH**  
Widenmayerstrasse 46  
80538 München  

**Handelsregister:** HRB 293133  
**Registergericht:** München  

**Vertreten durch:**  
Stefan Weiss, Geschäftsführer  

**Telefon:** +49 179 6716336  
**E-Mail:** [stefan.weiss@whatslegal.ai](mailto:stefan.weiss@whatslegal.ai)

## Umsatzsteuer-ID

Umsatzsteuer-Identifikationsnummer gemäß § 27 a Umsatzsteuergesetz:

## EU-Streitschlichtung

Die Europäische Kommission stellt eine Plattform zur Online-Streitbeilegung (OS) bereit:  
[https://ec.europa.eu/consumers/odr/](https://ec.europa.eu/consumers/odr/)

Unsere E-Mail-Adresse finden Sie oben im Impressum.

## Verbraucherstreitbeilegung
Wir sind nicht bereit oder verpflichtet,
 an Streitbeilegungsverfahren vor einer Verbraucherschlichtungsstelle teilzunehmen.

**Quelle:**  
[https://www.e-recht24.de/impressum-generator.html](https://www.e-recht24.de/impressum-generator.html)
`);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div >
      <Header />
      <TextComponent content={content} />
      {isMobile ? <Footer2Mobile /> : <Footer2 />}
    </div>
  );
};

export default Imprint;
