// src/pages/Home.tsx
import React, { useState } from 'react';
import Header from '../components/Header';
import WhyWhatsLegal from '../components/WhyWhatsLegal';
import SecondHeader from '../components/SecondHeader';
import Footer2 from '../components/Footer2';
import Footer2Mobile from '../components/Footer2_Mobile';
import useWindowSize from '../hooks/useWindowSize';
import Hero from '../components/HeroSection';
import OverlayChatbot from '../components/OverlayChatbot';
import FloatingButton from '../components/FloatingButton';
import floatingChatIcon from "../assets/smiley-comment-alt.png";
import { Height } from '@mui/icons-material';

import homeImageMobile from '../assets/hero-image-mobile.jpg';
import homeImage from '../assets/hero-image.jpg';
import { homePageContent } from '../helpers/constants';

const Home: React.FC = () => {
  const { width } = useWindowSize();
  const isMobile = width <= 768;
  const [isChatbotOpen, setIsChatbotOpen] = useState(false);

  const openChatbot = () => setIsChatbotOpen(true);
  const closeChatbot = () => setIsChatbotOpen(false);

  return (
    <>
      <Header />
      <Hero normalImage={homeImage} mobileImage={homeImageMobile} heading={<>Der KI Chatbot <br /> für Gesetze</>} subHeading='Kostenlos. verständlich. für alle.' buttonHeading='Frage hier stellen'
            link='/whatslegalchat'/>
      <SecondHeader
        title={
          <>
            Chatten Sie einfach mit <br />
            5.000 aktuellen Gesetzen*
          </>
        }
        secondTitle={
          <>
            *deutsche Bundesgesetze und Verordnungen - Stand Dez. 2024
          </>
        }
      />
      {/* <ChatBot /> */}
      <WhyWhatsLegal content={homePageContent}/>

      <FloatingButton
        onClick={openChatbot}
        icon={<img src={floatingChatIcon} alt="Chat Icon" />}
      />
      <OverlayChatbot isOpen={isChatbotOpen} onClose={closeChatbot} />
      {/* <Banner /> */}
      {isMobile ? <Footer2Mobile /> : <Footer2 />}
    </>
  );
};

export default Home;
