import React from 'react';
import './css/About.css';
import ReactMarkdown from 'react-markdown';
import style from './css/markdown-tick-styles.module.css';
import rehypeRaw from 'rehype-raw';
interface AboutComponentProps {
    paragraphs: string[];
}

const AboutWithTickAndBackgroundComponent: React.FC<AboutComponentProps> = ({ paragraphs }) => {

    const containerStyle: React.CSSProperties = {
        backgroundColor: "#f0f0f0"
      };
    
    return (
        <section className="about" style={containerStyle}>
            <div className="about-content">
                {paragraphs.map((paragraph, index) => (
                     <ReactMarkdown key={index} className={style.reactMarkDown} rehypePlugins={[rehypeRaw]}>{paragraph}</ReactMarkdown>
                ))}
            </div>
        </section>
    );
};

export default AboutWithTickAndBackgroundComponent;
