import React from 'react';
import './css/SecondHeader.css';

interface SecondHeaderProps {
  title: React.ReactNode;
  secondTitle?: React.ReactNode
  backgroundColor?: String,
  textColor?: string,
  subTextColor?: string
}
const SecondHeader: React.FC<SecondHeaderProps> = ({ title, secondTitle, backgroundColor, textColor, subTextColor }) => {
  return (
    <div className="header" style={
      {
        '--background-color': `${backgroundColor ?? "#044557"}`,
      } as React.CSSProperties
    }>
      <span className="header-item"
        style={
          {
            '--text-color': `${textColor ?? "#FFFFFF"}`,
          } as React.CSSProperties
        }
      >{title}</span>
      {secondTitle != null && (
        <span className='subheader-item'
          style={
            {
              '--sub-text-color': `${subTextColor ?? "#FFFFFF"}`,
            } as React.CSSProperties
          }
        >{secondTitle}</span>
      )}
    </div>
  );
};
export default SecondHeader;
