// src/pages/Home.tsx
import React, { useState } from 'react';
import Header from '../components/Header';
import SecondHeader from '../components/SecondHeader';
import Footer2 from '../components/Footer2';
import Footer2Mobile from '../components/Footer2_Mobile';
import useWindowSize from '../hooks/useWindowSize';
import Hero from '../components/HeroSection';
import OverlayChatbot from '../components/OverlayChatbot';
import FloatingButton from '../components/FloatingButton';
import floatingChatIcon from "../assets/smiley-comment-alt.png";
import { Height } from '@mui/icons-material';

import whiteLabelMobile from '../assets/NewWhiteLabel_mobile.jpeg';
import whiteLabelImage from '../assets/NewWhiteLabel.jpeg';
import embeddedImage from '../assets/whatslegal_embedded.png';
import overlayImage from '../assets/whatslegal_overlay.png';
import { newwWiteLabelContent } from '../helpers/constants';
import TextVideo from '../components/TextVideo';
import VersionDisplay from '../components/VersionDisplay';
import { ReactComponent as CaptainFrankIcon } from '../assets/CaptainFrankLogo.svg';

import CaptainFrankLogo from '../assets/CaptainFrankLogo.png';

const NewWhiteLabel: React.FC = () => {
  const { width } = useWindowSize();
  const isMobile = width <= 768;
  const [isChatbotOpen, setIsChatbotOpen] = useState(false);

  const openChatbot = () => setIsChatbotOpen(true);
  const closeChatbot = () => setIsChatbotOpen(false);
  const captainFrankRedirectLink = "https://www.captain-frank.com/de/cflegalai";

  return (
    <>
      <Header />
      <Hero normalImage={whiteLabelImage} mobileImage={whiteLabelMobile} heading={<>Mit generativer KI <br /> zu mehr Leads </>} subHeading={<>Nutzen Sie unseren KI Chatbot mit über<br/>4.500 aktuellen Gesetzen - in Ihrem Design</>} buttonHeading='Kundenbeispiel Captain Frank'
            link={captainFrankRedirectLink}/>
      <SecondHeader
        title={
          <>
            Wir integrieren Ihr Angebot<br />
            in der generierten KI Antwort
          </>
        }

        backgroundColor="#044557"
      />
      <VersionDisplay embeddedImage={embeddedImage} overlayImage={overlayImage}/>
      <TextVideo content={newwWiteLabelContent} showButton={"true"} videoLink='https://share.synthesia.io/embeds/videos/cc7f6069-58a4-4748-a24f-77f788cfc8f3' buttonText="Kontaktieren Sie uns" />

      <FloatingButton
        onClick={openChatbot}
        icon={<img src={floatingChatIcon} alt="Chat Icon" />}
      />
      <OverlayChatbot isOpen={isChatbotOpen} onClose={closeChatbot} />

      <SecondHeader
        title={
          <>
            Kunden/ Referenzen:&nbsp;
            <a
              href={captainFrankRedirectLink}
              target="_blank"
              rel="noopener noreferrer">
              <img
                src={CaptainFrankLogo}
                alt="Captain Frank Logo"
              />
            </a>
          </>
        }
        backgroundColor="#044557"
      />
      {/* <Banner /> */}
      {isMobile ? <Footer2Mobile /> : <Footer2 />}
    </>
  );
};

export default NewWhiteLabel;
