// src/pages/Home.tsx
import React, { useState } from 'react';
import Header from '../components/Header';
import ChatBot from '../components/ChatBot';
import WhyWhatsLegal from '../components/WhyWhatsLegal';
import SecondHeader from '../components/SecondHeader';
import Footer2 from '../components/Footer2';
import Footer2Mobile from '../components/Footer2_Mobile';
import useWindowSize from '../hooks/useWindowSize';
import { homePageWhyWhatslegalContent } from '../helpers/constants';

const WhatsLegalChat: React.FC = () => {
  const { width } = useWindowSize();
  const isMobile = width <= 768;

  return (
    <> 
      <Header />
      {/* <Banner /> */}
      <ChatBot />
      <WhyWhatsLegal content={homePageWhyWhatslegalContent}/> 
      {isMobile ? <Footer2Mobile /> : <Footer2 />}
    </>
  );
};

export default WhatsLegalChat;
